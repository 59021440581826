<template>
  <div class="stage" :style="{ background: currentContent.backgroundColor}">
    <div class="switcher left" @click="backwardContent">
      <img src="../assets/chevron_l.png" />
    </div>

    <ContentDisplayer ref="displayer" :content="currentContent" />

    <div class="switcher right" @click="forwardContent">
      <img src="../assets/chevron_r.png" />
    </div>
  </div>
</template>

<script>
import ContentDisplayer from './ContentDisplayer.vue';

export default {
  name: 'Stage',
  components: {
    ContentDisplayer
  },
  created() {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'ArrowRight') {
        this.forwardContent();
      } else if (e.key === 'ArrowLeft') {
        this.backwardContent();
      }

      this.$emit("content-change", this.currentContent.backgroundColor);
    });
  },
  methods: {
    backwardContent() {
      this.currentPosition--;
      if (this.currentPosition < 0) {
        this.currentPosition = this.contents.length - 1;
      }
      this.currentContent = this.contents[this.currentPosition];
    },
    forwardContent() {
      this.currentPosition++;
      if (this.currentPosition >= this.contents.length) {
        this.currentPosition = 0;
      }
      this.currentContent = this.contents[this.currentPosition];
    }
  },
  data() {
    return {
      currentPosition: 0,
      contents: [
        {
          title: "Sobre mim",
          backgroundColor: "#20DB8D",
          image: "me.gif",
          description: "Olá! 🙋 <br/><br/> Esse carinha aí em cima sou eu, me chamo Marconi, tenho 21 anos, trabalho como engenheiro de software, estudo Ciência da Computação na UFPE e moro na maior capital em linha reta do nordeste, Recife! ☀️ <br/><br/> Clica na setinha ali do lado 👉"
        },
        {
          title: "Música",
          backgroundColor: "#2CC3DB",
          image: "otter-dance.gif",
          description: 'Tanto quanto gosto de lontras, gosto de ouvir música. 🦦 <br/> Vivo fazendo <b><a target="_blank" href="https://open.spotify.com/user/22mvxzqvkotfuthzgjqqxtkei?si=f5b6b08b84ff413e">playlists do Spotify</a></b>, uma pra cada momento que posso estar e gosto de misturar alguns estilos nelas. 🎧 <br/> Se quiser ouvir essa aqui embaixo é uma das minhas preferidas, mas cuidado com o volume! 👀',
          externalLinks: {
            first: '<iframe class="spotify" src="https://open.spotify.com/embed/track/19kX6hSlYH31js2SL4jgrj" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>'
          }
        },
        {
          title: "Projetinhos",
          backgroundColor: "#49DB40",
          image: "projects.png",
          description: 'Além de trabalhar com programação também gosto de fazer por diversão, dependendo da ideia pra ser feita 🤓 <br/><br/> Durante a pandemia participei de alguns hackathons com amigos, num deles fomos premiados pelo o Soilfleet (esse de cima) 🌱 <br/> Além disso fiz uma extensão pra ver vídeos do Youtube enquanto jogo, a YTGBO (essa de baixo) 🎮 <br/><br/>',
          externalLinks: {
            first: '<div style="display: flex; justify-content: center;"><a href="https://github.com/MarconiGRF" target="_blank" class="github" ><span>Outros projetos</span></div></div>'
          }
        },
        {
          title: "PETiano",
          backgroundColor: "#DB680B",
          image: "pet.png",
          description: "Além dessas coisas participei do PET-Informática de 2019 até 2021, uma das minhas experiências mais incríveis da graduação 🧡 <br/><br/> Lá pude aprender bastante sobre como trabalhar em equipe e criar projetos do zero que fizeram e até hoje fazem a diferença nas vidas de atuais e futuros estudantes do CIn 🤗<br/>"
        },
        {
          title: "Porquê quero entrar",
          backgroundColor: "#E8B620",
          image: "apple.png",
          description: "Gosto de aprender coisas novas, seja em programação ou outros tipos de conhecimento e sei que vou ter o suporte que preciso e assuntos que sempre me interessam como inovação e design!✨ <br/> E ao mesmo tempo contribuir com minha bagagem de desenvolvimento de software para meus (futuros) colegas de projeto 😊 <br/><br/>"
        }
      ],
      currentContent: {}
    }
  },
  mounted() {
    this.currentContent = this.contents[0];
  }
}
</script>

<style scoped>
.switcher {
  display: flex;
  align-items: center;
  max-width: 15vh;
}

.left img:hover {
  transform: translate(-.5em, 0px);
}

.right img:hover {
  transform: translate(.5em, 0px);
}

.switcher img {
  max-width: 5vh;
  padding: 2rem;
  cursor: pointer;
  transition: all .4s ease-in-out;
}

.stage {
  display: flex;
  align-self: center;
  height: 100vh;
  width: 100vw;
  justify-content: space-between;
  transition: background .4s ease-in-out;
}
</style>
