<template>
  <div>
    <Stage :ref="stage" />
  </div>
</template>

<script>
import Stage from './components/Stage.vue'

export default {
  name: 'App',
  components: {
    Stage
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');

body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  align-items: center;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.spotify {
  border-radius: .75em;
}

a {
  color: white;
}

.github {
  height: 3.5rem;
  width: 15rem;
  background-color: white;
  color: #49DB40;
  font-family: PT Sans;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .75em;
  text-decoration: underline;
  cursor: pointer;
}
</style>
